
















































import Vue from 'vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

import Modal from '@/components/Modal.vue';
import { formatAttestationDate } from '@/helpers/dates';

export default Vue.extend({
  name: 'AsymptomaticAttestation',
  components: {
    ConfirmDialog,
    Modal,
  },
  data() {
    return {
      studentId: '',
      formattedDate: '',
      showConfirmDialog: false,
    };
  },
  async mounted() {
    this.formattedDate = formatAttestationDate(
      this.$store.state.attestation.date
    );
    try {
      const user = await this.$auth.currentAuthenticatedUser();
      this.studentId = user.attributes['custom:student_id'];
    } catch (e) {
      this.$auth.federatedSignIn();
    }
  },
  methods: {
    async signOut() {
      try {
        await this.$auth.signOut();
      } catch (e) {
        this.$store.dispatch('setActiveModal', 'signOutFailed');
      }
    },
    showDialog() {
      this.showConfirmDialog = true;
    },
    resetDialog() {
      this.showConfirmDialog = false;
    },
  },
});
