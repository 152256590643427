






import Vue from 'vue';
import Attestation from '@/components/Attestation/Attestation.vue';

export default Vue.extend({
  name: 'AttestationPage',
  components: { Attestation },
});
