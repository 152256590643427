













import Vue from 'vue';
import { mapState } from 'vuex';

import AttestationPrompt from '@/components/Attestation/AttestationPrompt.vue';
import AsymptomaticAttestation from '@/components/Attestation/AsymptomaticAttestation.vue';
import SymptomaticAttestation from '@/components/Attestation/SymptomaticAttestation.vue';
import RemoteAttestation from '@/components/Attestation/RemoteAttestation.vue';
export default Vue.extend({
  name: 'Attestation',
  components: {
    AsymptomaticAttestation,
    AttestationPrompt,
    SymptomaticAttestation,
    RemoteAttestation,
  },
  computed: mapState(['attestation']),
});
