



































import Vue from 'vue';
import { getIdToken } from '@/plugins/auth';
import Modal from '@/components/Modal.vue';

export default Vue.extend({
  name: 'AttestationPrompt',
  components: {
    Modal,
  },
  data() {
    return {
      symptoms: [
        'CoughSymptom',
        'ShortBreathSymptom',
        'FeverSymptom',
        'ChillsSymptom',
        'MuscleAcheSymptom',
        'SoreThroatSymptom',
        'GISymptom',
        'LossOfTasteSymptom',
        'RespiratorySymptom',
      ],
    };
  },
  methods: {
    async submitAttestation(
      symptomatic?: boolean,
      remote?: boolean
    ): Promise<void> {
      const idToken = await getIdToken();
      await this.$store.dispatch('createAttestation', {
        symptomatic,
        remote,
        idToken,
      });
      if (
        !this.$store.state.attestation &&
        this.$store.state.errors.attestation
      ) {
        this.$store.dispatch('setActiveModal', 'attestationFailed');
      }
    },
    async attest(symptomatic: boolean): Promise<void> {
      return this.submitAttestation(symptomatic);
    },
    async remote(): Promise<void> {
      return this.submitAttestation(undefined, true);
    },
  },
});
