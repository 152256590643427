





















import Vue from 'vue';

export default Vue.extend({
  name: 'ConfirmDialog',
  props: {
    show: Boolean,
    closeFunction: Function,
  },
  data() {
    return {
      showModal: this.show,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val): void {
        this.showModal = val;
      },
    },
  },
  methods: {
    handleClick(): void {
      this.close();
      this.closeFunction();
    },
    close(): void {
      this.$emit('dialog-close');
      this.showModal = false;
    },
  },
});
